import { useState } from 'react';
import Plus from '../icons/plus.svg?react';
import { useTranslation } from 'react-i18next';
import { FormDialog } from '../base/dialogs/FormDialog';
import { Button, Stack, Typography } from '@mui/material';
import { TextField } from '../base/forms/TextField';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'component/hooks/useSnackbar';
import AutomcompleteField from '../base/forms/AutocompleteField';
import { CreateSiteTemplateRequest, useCreateSiteTemplate, useSearchSites } from 'api/site';

export default function CreateSiteTemplate() {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useCreateSiteTemplate();
  const { enqueueSnackbar } = useSnackbar();
  const [siteInputValue, setSiteInputValue] = useState<string>('');
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const methods = useForm<CreateSiteTemplateRequest>({
    defaultValues: {
      name: '',
      site_id: undefined,
      notes: '',
      tags: [],
    },
  });

  function handleClose() {
    methods.reset();
    setShowAddDialog(false);
  }

  const onSubmit = async (data: CreateSiteTemplateRequest) => {
    await mutateAsync(data);
    methods.reset();
    enqueueSnackbar(t('site_template_created_successfully'), {
      key: 'createSiteTemplate',
      variant: 'success',
    });
    setShowAddDialog(false);
  };

  const { data: sitesData } = useSearchSites(siteInputValue);

  const sites =
    sitesData?.data.result?.map(site => {
      return {
        label: site.label ? `${site.label} - ${site.domain}` : site.domain,
        value: String(site.id) ?? '',
      };
    }) ?? [];

  return (
    <>
      <FormDialog
        form={
          <Stack spacing={4} direction="column">
            <Typography variant="body2" color="greys.900">
              {t('create_template_description')}
            </Typography>
            <TextField
              name="name"
              label={t('template_name')}
              disabled={isPending}
              fullWidth
              rules={{ required: true }}
            />
            <AutomcompleteField
              name="site_id"
              options={sites}
              isLoading={isPending}
              label={t('sites')}
              placeholder={t('select_a_site')}
              rules={{ required: true }}
              onInputValueChange={value => setSiteInputValue(value)}
            />
            <TextField
              name="notes"
              multiline
              rows="3"
              placeholder={t('enter_paths_placeholder')}
              disabled={isPending}
              fullWidth
              required
              label={t('add_note')}
            />
          </Stack>
        }
        methods={methods}
        onSubmit={onSubmit}
        onClose={handleClose}
        open={showAddDialog}
        title={
          <Stack gap={1}>
            <Typography variant="body1" fontWeight={600} color="greys.900">
              {t('create_new_type', {
                type: t('template'),
              })}
            </Typography>
            <Typography variant="body2" color="greys.900">
              {t('create_template_subheading')}
            </Typography>
          </Stack>
        }
        submitText={t('create')}
      />
      <Button
        onClick={() => {
          setShowAddDialog(true);
        }}
        size="medium"
        variant="contained"
        color="primary"
        startIcon={<Plus />}
      >
        {t('create_new_type', {
          type: t('template'),
        })}
      </Button>
    </>
  );
}
